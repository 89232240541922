import { render, staticRenderFns } from "./CrudEditUser.vue?vue&type=template&id=a5703452&scoped=true&"
import script from "./CrudEditUser.vue?vue&type=script&lang=js&"
export * from "./CrudEditUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5703452",
  null
  
)

export default component.exports