<template>
  <div>
    <v-container grid-list-xl>
      <v-layout
        row
        wrap>
        <v-flex xs12>
          <app-card
            :footer="true"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <v-btn
              class="btn-back"
              flat
              icon
              @click="historyBack"
            >
              <v-icon color="dark">
                arrow_back
              </v-icon>
            </v-btn>
            <h4 class="my-4">User information</h4>
            <v-form
              v-if="user"
              lazy-validation>
              <v-layout
                row
                wrap>
                <v-flex
                  xs12
                  sm4>
                  <v-text-field
                    v-model="user.firstName"
                    label="First name"
                    @blur="blurSaving"
                    @input="inputSaving"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm4>
                  <v-text-field
                    v-model="user.lastName"
                    label="Last name"
                    @blur="blurSaving"
                    @input="inputSaving"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm4>
                  <v-text-field
                    v-model="user.email"
                    label="Email"
                    @blur="blurSaving"
                    @input="inputSaving"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm4>
                  <v-text-field
                    v-model="user.phone"
                    label="Phone"
                    @blur="blurSaving"
                    @input="inputSaving"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm4>
                  <v-text-field
                    v-model="user.country"
                    label="Country"
                    @blur="blurSaving"
                    @input="inputSaving"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm4>
                  <v-text-field
                    v-model="user.language"
                    label="Language"
                    @blur="blurSaving"
                    @input="inputSaving"
                  />
                </v-flex>
                <v-flex
                  v-if="roleList"
                  xs12
                  sm6>
                  <v-select
                    :items="Object.keys(roleList)"
                    :rules="[v => v.length>0 || 'Please select at least 1 user role']"
                    v-model="user.roles"
                    multiple
                    label="Roles"
                    @change="blurSaving"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6>
                  <v-select
                    v-if="brandList.length"
                    :items="brandList"
                    :rules="[v => v.length>0 || 'Please select at least 1 brand']"
                    v-model="user.brands"
                    :item-text="(val) => { return val.name }"
                    clearable
                    item-value="id"
                    multiple
                    label="Brands"
                    @change="blurSaving"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm3>
                  <v-switch
                    v-model="user.active"
                    label="Active"
                    @change="blurSaving"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm9>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="user.plainPassword"
                    :append-icon="showPasswordField ? 'visibility' : 'visibility_off'"
                    :type="showPasswordField ? 'text' : 'password'"
                    label="Plain password"
                    @click:append="showPasswordField = !showPasswordField"
                    @blur="blurSaving"
                    @input="inputSaving"
                  />
                </v-flex>
                <v-flex
                  id="login"
                  xs6>
                  <v-overflow-btn
                    :disabled="!user.email"
                    :items="loginOptions"
                    label="Select login option..."
                    segmented
                    class="login-btn"
                    target="#login"
                  />
                </v-flex>
                <v-flex
                  xs3>
                  <v-btn
                    :loading="loadingSync"
                    color="primary"
                    @click="() => { syncUser(user.id) }"
                  >Sync HS</v-btn>
                </v-flex>
                <v-flex xs12>
                  <v-btn
                    color="primary"
                    @click="saveFunction()"
                  >Save</v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </app-card>
        </v-flex>
      </v-layout>
      <page-saving-status :status="savingStatus" />
    </v-container>
  </div>
</template>

<script>
import lodash from 'lodash'
import Request from '../../../../helpers/request'
import AppConfig from 'Constants/AppConfig'
import Brands from '../../../../helpers/brands'
import Roles from '../../../../helpers/roles'
import { TEXT_FIELD_MAX_LENGTH, SAVING_STATUS } from '../../../../constants/common'
import PageSavingStatus from 'Components/PageSavingStatus/PageSavingStatus'

export default {
  components: { PageSavingStatus },
  mixins: [Request, Brands, Roles],
  data () {
    return {
      savingStatus: SAVING_STATUS.default,
      TEXT_FIELD_MAX_LENGTH,
      user: null,
      initUser: null,
      brandList: [],
      roleList: [],
      showPasswordField: false,
      loginOptions: [],
      loadingSync: false
    }
  },
  watch: {
    user: {
      handler (user) {
        const filteredRoles = ['ROLE_PASSENGER', 'ROLE_DRIVER']

        let loginOptions = []

        user.brands.forEach(brand => {
          user.roles.filter(role => filteredRoles.includes(role)).forEach(role => {
            loginOptions.push({
              text: role === 'ROLE_PASSENGER' ? `Login as passenger (${brand.name})` : `Login as driver (${brand.name})`,
              callback: () => {
                window.open(`${AppConfig.BASE_URL[brand.name][AppConfig.env].replace('rentabob', 'driveme').replace('edrivers', 'driveme')}/one-time-login/user/${user.id}/${brand.id}/${role === 'ROLE_PASSENGER' ? 'passenger' : 'driver'}`, '_blank')
              }
            })
          })
        })

        this.loginOptions = loginOptions
      },
      deep: true
    }
  },
  async beforeMount () {
    await this.request('GET', `/users/${this.$route.params.id}`, {}, ({ data }) => {
      this.user = {
        id: data.id,
        active: data.active,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone ? data.phone.number : '',
        country: data.country,
        language: data.language,
        roles: data.roles,
        brands: data.brands,
        plainPassword: data.plainPassword
      }
    })

    try {
      this.brandList = await this.fetchBrands()
      this.roleList = await this.fetchRoles()
      this.initUser = lodash.cloneDeep(this.user)
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    async syncUser (userId) {
      try {
        this.loadingSync = true
        await this.request('POST', `/hubspot/user/${userId}/sync`)
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Sync HS successfull'
        })
      } catch (error) {
      } finally {
        this.loadingSync = false
      }
    },
    inputSaving () {
      if (!lodash.isEqual(this.user, this.initUser)) {
        this.savingStatus = SAVING_STATUS.wait
      } else {
        this.savingStatus = SAVING_STATUS.default
      }
    },
    async blurSaving () {
      if (!lodash.isEqual(this.user, this.initUser)) {
        try {
          await this.saveFunction()
          this.savingStatus = SAVING_STATUS.saved
        } catch (error) {
          this.savingStatus = SAVING_STATUS.error
        }
      }
    },
    async saveFunction () {
      let body = {
        data: {
          'active': this.user.active,
          'email': this.user.email,
          'name': this.user.firstName + ' ' + this.user.lastName,
          'phone': this.user.phone,
          'country': this.user.country,
          'roles': this.user.roles.join(','),
          'language': this.user.language,
          'brands': this.user.brands.map(brand => brand.id || brand),
          'plainPassword': this.user.plainPassword
        }
      }

      await this.request('PATCH', `/users/${this.user.id}`, body, () => {
        this.showPasswordField = false
        // this.$notify({
        //   group: 'topRight',
        //   type: 'success',
        //   text: 'Gebruiker is gewijzigd'
        // })
      })

      this.initUser = lodash.cloneDeep(this.user)
    },
    historyBack () {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
